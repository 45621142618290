//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import chineseLanguageLoader from "chinese-language-loader/lib/main.js";

export default {
    props: ['product'],
    data() {
        return {
            locale: window.localStorage.getItem('locale') || this.$i18n.locale,
            currency: window.localStorage.getItem('currency'),
            uniacid: window.localStorage.getItem('uniacid'),
            productNames: {
                17: {
                    "NS_01": "Slim Head Toothbrush",
                    "NS_02": "Double Layer Bristle Toothbrush",
                    "NS_03": "Deep Cleansing Toothbrush (Super Soft Bristles)",
                    "NS_04": "Deep Cleansing Toothbrush (Regular Bristles)",
                    "NS_05": "Toothbrush (Soft Bristles)",
                    "NS_06": "Long Handle Interdental Brush - 0.6MM (pack of 6)",
                    "NS_07": "Long Handle Interdental Brush - 0.7MM (pack of 6)",
                    "NS_08": "Long Handle Interdental Brush - 0.5MM (pack of 6)"
                },
                20: {
                    "UF-J": "Unique Fencing Jacket",
                    "UF-T": "Unique Fencing Tee",
                    "B4": "Junior Rollbag",
                    "U6": "Chest Protector (Men/Women)",
                    "E2": "Epee Mask with Replacement Padding (350N)",
                    "E3": "Epee Body Wire",
                    "E1-A": "Epee Blade",
                    "E1": "Epee Electric Complete",
                    "U2-FIE": "Fencing Underplastron (FIE800N)",
                    "U3": "Fencing Breeches (350N)",
                    "U2": "Fencing Underplastron (CE350N)",
                    "U1": "Fencing Jacket (350N)",
                    "S6": "Sabre Mask Wire",
                    "S14": "Leon Paul Standard Epee Electric Blade (Handle)",
                    "S24": "Uhlmann FIE 1600N Variously Extra Dual-Use Mask",
                    "S23": "Allstar Inox FIE Foil Mask",
                    "S22": "Allstar Comfort FIE Sabre Mask",
                    "S21": "Uhlmann FIE 1600N Extra Sabre Mask",
                    "S20": "Uhlmann Treasure Fencing Bag",
                    "S19": "Leon Paul Team Bag",
                    "S18": "Allstar Fencing Bag",
                    "S17": "Marek/BF FIE Epee Straight Handle Electric Blade",
                    "S16": "Uhlmann Epee Straight Handle Electric Blade, Marek/BF FIE Blue Blade",
                    "S15": "Leon Paul Epee Handle",
                    "S13": "Leon Paul Standard Adult Epee Electric Complete Handle",
                    "S12": "Uhlmann Combination Power Gloves",
                    "S11": "Uhlmann Combination “Basic” 2.0 Gloves",
                    "S10": "Allstar Ecostar FIE Men’s Fencing Breeches",
                    "S9": "Allstar Ecostar FIE Women’s Fencing Breeches",
                    "S8": "Uhlmann “Classic” 350 N Men’s Fencing Jacket",
                    "S7": "Uhlmann “World Cup” 800 N Women’s Fencing Jacket",
                    "SFIE-1": "Leon Paul X-change FIE Traditional Sabre Mask",
                    "S4-A": "Uhlmann Electric Sabre Cuff",
                    "S4": "Electric Sabre Glove with Cuff",
                    "S2": "Sabre Mask (350N)",
                    "F1-A": "Foil Blade",
                    "S1-A": "Sabre Blade",
                    "S1": "Complete Sabre",
                    "S5": "Sabre Body Wire",
                    "UF_003": "Children's Fencing Course (Ages 3-6) 8 Sessions",
                    "UF_004": "Adult Fencing Training Course 8 Sessions",
                    "UF_002": "Foil Beginner Class <br/>(Primary School)",
                    "UF_001": "Sabre Course 8 Sessions"
                },
                24: {
                    "SK_001": "Mini storage  for 12 months, size 4x3 ,12 square feet",
                    "SK_002": "Mini storage  for 12 months, size 5x3 ,15 square feet",
                    "SK_003": "Mini storage  for 12 months, size 4x4 ,16 square feet",
                    "SK_004": "Mini storage  for 12 months, size 4x5 ,20 square feet",
                    "SK_005": "Mini storage  for 12 months, size 5x6 ,30 square feet",
                    "SK_007": "Smart storage for 12 months, size 4x4 ,16 square feet",

                },
            }
        }
              //this.currency= window.localStorage.getItem('currency');
        //console.log('this.currency')
       // console.log(this.currency)
    },
    created() {
        if (this.locale === 'zh-CN') {
            this.product.goods_name = chineseLanguageLoader(this.product.goods_name, { language: 'zh-CN' });
        } else if (this.locale === 'EN' && this.uniacid && this.productNames[this.uniacid]) {
            this.product.goods_name = this.productNames[this.uniacid][this.product.goods_id] || "Unknown Product";
        }
    },
    methods: {
        detail(id) {
            this.$router.push({ path:'/product', query:{id}})
        }
    }
}
