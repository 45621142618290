//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import i18n from '@/i18n'; 

export default {
    name: 'Header',
    data() {
        return {
            uniacid:window.localStorage.getItem('uniacid'),
            locale: 'EN',
            locales: [
                { value: "EN", label: "English" },
                { value: "zh-CN", label: "中文简体" },
                { value: "zh-HK", label: "中文繁體" },
            ],
            currency: 'HKD',
            currencies: [
                { value: "HKD", label: "HKD" },
                { value: "USD", label: "USD" },
            ],
            config: [
                { label: this.$t('index'), locate: 'index', path: '../home' },
                { label: this.$t('Login/Logon'), locate: 'Login/Logon', path: '/login' },
                { label: this.$t('Myorders'), locate: 'Myorders', path: '/user', type: '0' },
                { label: this.$t('Mycenter'), locate: 'Mycenter', path: '/user' },
                { label: this.$t('logout'), locate: 'logout', path: '../home' },
                { label: this.$t('Cart'), locate: 'Cart', path: '/cart', type: 'cart', class: 'active', icon: '&#xe70b;' },
            ],
            userInfo: null,
            showCurrency: false,
            username: null, // Add username data property
        }
    },
    created() {
        const locale = window.localStorage.getItem('locale') || this.$i18n.locale;
        this.locale = locale;
        this.$i18n.locale = locale;

        const currency = window.localStorage.getItem('currency');
        if (!currency || currency === null || currency.length < 2) {
            this.currency = 'HKD';
            window.localStorage.setItem('currency', 'HKD');
        } else {
            this.currency = currency;
        }

        var url = window.location.href;
        var dz_url = url.split("#")[0];
        var www_dz_url = dz_url.split("/")[2];
        var centerdomain = www_dz_url.split(".");
        if (centerdomain[0] === 'trillionglory') {
            this.showCurrency = true;
        }
        this.uniacid = window.localStorage.getItem('uniacid');
        
        // Retrieve username from local storage
        this.username = window.localStorage.getItem('username');
        
        // Update config array based on login status
        this.updateConfig();
    },
    methods: {
        handleClick(item) {
            if (item.locate === 'logout') {
                this.confirmLogout();
            } else {
                this.goto(item.path, item.type);
            }
        },
        goto(path, type = '0') {
            if (path == '/login' && this.userInfo) {
                this.setCookie('PHPSESSID', '', -1);
                this.$store.commit('saveUserInfo', null);
                window.localStorage.setItem('fb_user_id', '');
                window.localStorage.setItem('fb_name', '');
                this.$router.push({ name: 'Home' });
            } else {
                this.$router.push({ path, query: { type } });
            }
        },
        setCookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + "; " + expires;
        },
        changeLanguage(value) {
            this.locale = value;
            window.localStorage.setItem('locale', value);
            this.$i18n.locale = value;
            window.location.reload();
},
        changeCurrency(value) {
            this.currency = value;
            window.localStorage.setItem('currency', value);
            window.location.reload();
        },
        confirmLogout() {
            this.$confirm('確定登出 ?', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'warning'
            }).then(() => {
                this.setCookie('PHPSESSID', '', -1);
                this.$store.commit('saveUserInfo', null);
                window.localStorage.setItem('fb_user_id', '');
                window.localStorage.setItem('fb_name', '');
                window.localStorage.setItem('login@token', '');
                window.localStorage.setItem('user_id', '');
                window.localStorage.setItem('username', '');
                this.$router.push({ name: 'Home' });

                this.$confirm('You have logged out', {
                    confirmButtonText: 'OK',
                    showCancelButton: false,
                    type: 'info'
                }).then(() => {
                    window.location.reload(); // Refresh the page after confirming logout
                });
            }).catch(() => {
                // User canceled logout, do nothing
            });
        },
        updateConfig() {
            if (this.username) {
                // If user is logged in, replace the login/logon item with a greeting
                this.config = this.config.map(item => 
                    item.locate === 'Login/Logon' ? 
                    { label: `Hello，${this.username}`, locate: `Hello，${this.username}`, path: '' } : item
                );
            } else {
                // If user is not logged in, ensure the login/logon item is present
                if (!this.config.some(item => item.locate === 'Login/Logon')) {
                    this.config.splice(1, 0, { label: this.$t('Login/Logon'), locate: 'Login/Logon', path: '/login' });
                }
            }
        }
    },
    watch: {
        '$store.state.userInfo': function (info) {
            this.userInfo = info;
            if (this.userInfo) {
                this.config[1].label = this.$t('Logout');
                this.config[1].locate = this.$t('Logout');
            } else {
                this.config[1].label = this.$t('Login/Logon');
            }
            this.updateConfig(); // Update config when userInfo changes
        }
    }
}
