//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ['menu', 'webset'],
    data() {
        return {
            uniacid: parseInt(window.localStorage.getItem('uniacid')),
            active: '',
            locale: this.$i18n.locale,
            hardCodedNames: [
                "Course", "Gloves", "Sword Uniform", "Mask",
                "Sword Blade / Sword Accessories", "Sword Pants", "Sword Bag"
            ]
        }
    },
    created() {
        console.log('uniacid:', this.uniacid, 'locale:', this.$i18n.locale);
        console.log('Menu:', this.menu);
    },
    methods: {
        handleSelect(e) {
            console.log(e);
        },
        getHardCodedName(name, index) {
            if (this.uniacid === 20 && this.locale === 'EN' && index >= 0 && index <= 6) {
                return this.hardCodedNames[index];
            }
            return name;
        },
        goto() {
            // Your navigation logic
        }
    }
}
